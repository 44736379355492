<template>
    <div class="vx-col md:w-full sm:w-auto">
        <div class="vx-row md:w-full">
            <vx-tooltip class="position-icon" text="Xóa" position="top">
                <feather-icon icon="TrashIcon" class="action-err icon-btn"
                              svgClasses="h-5 w-5 hover:text-danger cursor-pointer"
                              @click="confirmDeleteRecord"/>
            </vx-tooltip>
        </div>
    </div>
</template>

<script>
export default {
    name: 'CellRendererActions',
    methods: {
        editRecord() {
            this.$router.push('/user/edit-notification?announcementId=' + this.params.data.id).catch(() => {
                this.showUpdateSuccess();
            })
        },
        showUpdateSuccess() {
            this.$vs.notify({
                color: 'success',
                text: 'Cập nhật thành công',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        },
        confirmDeleteRecord() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: `Xác nhận xoá`,
                text: `Bạn có chắc là muốn xoá bảng tin này không ?`,
                accept: this.deleteRecord,
                acceptText: "Xoá",
                cancelText: "Huỷ"
            })
        },
        deleteRecord() {
            this.$vs.loading();
            this.$crm.post('/announcement/delete?announcementId=' + this.params.data.id).then(() => {
                this.$vs.loading.close();
                this.showDeleteSuccess();

            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
        showDeleteSuccess() {
            this.$store.commit('DELETE_NOTIFY', true);
            this.$vs.notify({
                color: 'success',
                text: 'Xoá thành công',
                iconPack: 'feather',
                icon: 'icon-alert-circle',
            })
        }
    }
}
</script>
