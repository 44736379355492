<template>
    <div id="page-branch-list">
        <div class="vx-card p-6">
            <div class="flex flex-wrap items-end">
                <div class="flex-grow">
                    <vs-button
                        @click="addCamera"
                        color="primary"
                        type="border"
                        icon-pack="feather"
                        icon="icon-plus">
                        Thêm thông báo
                    </vs-button>
                </div>
            </div>
            <!-- AgGrid Table -->
            <ag-grid-vue style="height: 55vh"
                         ref="agGridTable"
                         :components="components"
                         :gridOptions="gridOptions"
                         class="ag-theme-material w-100 my-4 ag-grid-table"
                         :columnDefs="columnDefs"
                         :defaultColDef="defaultColDef"
                         :rowData="organizationBranchList"
                         :animateRows="true"
                         :pagination="true"
                         :paginationPageSize="paginationPageSize"
                         :onGridReady="onGridReady"
                         :suppressPaginationPanel="true">
            </ag-grid-vue>

            <vs-pagination
                :total="totalPages"
                :max="maxPageNumbers"
                v-model="currentPage"/>
        </div>
    </div>
</template>

<script>
import {AgGridVue} from "ag-grid-vue"
import '@/assets/scss/vuexy/extraComponents/agGridStyleOverride.scss'
import Pagination from "../../ui-elements/Pagination.vue";
import CellRendererActions from "./cell-renderer/CellRendererActions.vue";
import CellRendererContent from "./cell-renderer/CellRendererContent";

export default {
    components: {
        AgGridVue,

        // Cell Renderer
        Pagination,
        CellRendererActions,
        CellRendererContent
    },
    data() {
        return {
            searchQuery: "",
            maxPageNumbers: 7,
            // AgGrid
            gridApi: null,
            gridOptions: {},
            defaultColDef: {},
            columnDefs: [
                {
                    headerName: 'Người nhận',
                    field: 'fullName',
                    width: 150
                },
                {
                    headerName: 'Tiêu đề',
                    field: 'title',
                    width: 170
                },
                {
                    headerName: 'Nội dung',
                    field: 'content',
                    cellRendererFramework: 'CellRendererContent',
                    width: 250
                },
                {
                    headerName: 'Hành động',
                    field: 'actions',
                    width: 80,
                    cellRendererFramework: 'CellRendererActions',
                },
            ],
            organizationBranchList: [],
            components: {
                CellRendererActions,
                CellRendererContent
            },
        }
    },
    created() {
        this.initScreen();
    },
    watch: {
        '$store.state.windowWidth'(val) {
            if (val <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (val > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        '$store.state.reduceButton'() {
            setTimeout(() => {
                this.gridApi.sizeColumnsToFit();
            }, 650);
        },
        '$store.state.deleteNotification'(val) {
            if (val) {
                this.initScreen();
                this.$store.commit('DELETE_NOTIFY', false);
            }
        },
    },
    computed: {
        paginationPageSize() {
            if (this.gridApi) return this.gridApi.paginationGetPageSize();
            else return 20
        },
        totalPages() {
            if (this.gridApi) return this.gridApi.paginationGetTotalPages();
            else return 0
        },
        currentPage: {
            get() {
                if (this.gridApi) return this.gridApi.paginationGetCurrentPage() + 1;
                else return 1
            },
            set(val) {
                this.gridApi.paginationGoToPage(val - 1)
            }
        }
    },
    methods: {
        addCamera() {
            this.$router.push('/user/add-notification').catch(() => {
            })
        },
        onGridReady() {
            if (this.$store.state.windowWidth <= 1024) {
                this.maxPageNumbers = 5;
            } else {
                this.maxPageNumbers = 7;
            }

            if (this.$store.state.windowWidth > 1024) {
                this.gridApi.sizeColumnsToFit();
            }
        },
        initScreen() {
            this.$vs.loading();
            this.$crm.get('/announcement/find-all').then((response) => {
                this.organizationBranchList = response.data.map(item => {
                    item.fullName = item.employeeDetailResponseDTOS.map(x => x.fullName).join(", ");
                    return item
                });
                this.$vs.loading.close();
                setTimeout(() => {
                    if (this.$store.state.windowWidth > 1024) {
                        this.gridApi.sizeColumnsToFit();
                    }
                }, 100);
            }).catch((err) => {
                this.$vs.loading.close();
                return this.$vs.notify({
                    text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                    color: 'danger'
                });
            });
        },
    },
    mounted() {
        this.gridApi = this.gridOptions.api;
    },
}
</script>

<style scoped>

</style>
